import React from 'react';
import styled from 'styled-components';
import { useDarkModeManager } from '../../state/user/hooks';
import Twiter from '../../assets/svg/twiter_ic_light.svg';
import TwiterDark from '../../assets/svg/twitter_dark.svg';
import Teligram from '../../assets/svg/teligram_ic_light.svg';
import TeligramDark from '../../assets/svg/teligram_dark.svg';
import Medium from '../../assets/svg/mideum_ic_light.svg';
import MediumDark from '../../assets/svg/midium_dark.svg';

const AsFooter = styled.div`
  padding: 12px;
  margin-top: 4em;
`;
const MuiGridContainer = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  margin-top: -16px;
  width: calc(100% + 16px);
  margin-left: -16px;
`;
const MuiGridGridLg4 = styled.div`
  padding-left: 16px;
  padding-top: 16px;
  flex-basis: 33.333333%;
  flex-grow: 0;
  max-width: 33.333333%;
  ${({ theme }) => theme.mediaWidth.upToExtraSm`
        flex-basis: 100%;
        max-width: 100%;
    `};
`;

const ForMain = styled.div`
  margin-top: 30px;
`;
const FooterH5 = styled.h5`
  font-weight: 700;
  font-size: 16px;
  line-height: 30px;
  color: ${({ theme }) => theme.text1};
`;
const FooterLink = styled.a`
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: ${({ theme }) => theme.text3};
  letter-spacing: 0.32px;
  text-decoration: none;
  transition: all 0.2s ease-out;
  margin-bottom: 8px;
  display: block;
  :hover {
    color: ${({ theme }) => theme.text1};
  }
`;

export default function Header() {
  const [darkMode] = useDarkModeManager();
  return (
    <>
      <AsFooter className="botm_links">
        <MuiGridContainer>
          {/* <MuiGridGridLg4>
                        <CxPadBox>
                            <TopCntnt>
                                <LeftBox>
                                    <img width={'100px'} src={darkMode ? LogoDark : Logo} alt="logo" />
                                </LeftBox>
                                <RightBox>
                                        <ButButton>Buy ZKDEFI</ButButton>
                                </RightBox>
                            </TopCntnt>
                            <BottomContent>
                                <div>
                                    <LeftSide>
                                        <LeftP>Max supply</LeftP>
                                        <LeftClmn>:</LeftClmn>
                                    </LeftSide>
                                    <LeftSide>
                                        <LeftP>Circulating supply</LeftP>
                                        <LeftClmn>:</LeftClmn>
                                    </LeftSide>
                                    <LeftSide>
                                        <LeftP>Sell Tax</LeftP>
                                        <LeftClmn>:</LeftClmn>
                                    </LeftSide>
                                    <LeftSide>
                                        <LeftP>Total Burned</LeftP>
                                        <LeftClmn>:</LeftClmn>
                                    </LeftSide>
                                    <LeftSide>
                                        <LeftP>Market Cap</LeftP>
                                        <LeftClmn>:</LeftClmn>
                                    </LeftSide>
                                </div>
                                <div>
                                    <Righth4>50.000.000</Righth4>
                                    <Righth4>5.000.000</Righth4>
                                    <Righth4>3%</Righth4>
                                    <Righth4>0</Righth4>
                                    <Righth4>$500.000</Righth4>
                                </div>
                            </BottomContent>
                        </CxPadBox>
                    </MuiGridGridLg4> */}
          <MuiGridGridLg4 className="footer_containt_grid">
            <ForMain className="footer_containt">
              <FooterH5>About</FooterH5>
              <FooterLink href="https://asteroneo.com">Landing Page</FooterLink>
              <FooterLink href="https://docs.asteroneo.com">Documents</FooterLink>
              <FooterLink href="https://asteroneo.com/tos">Terms of Service</FooterLink>
              <FooterLink href="mailto:admin@asteroneo.com" target="_blank">
                Contact Us
              </FooterLink>
            </ForMain>
          </MuiGridGridLg4>
          <MuiGridGridLg4 className="footer_containt_grid">
            <ForMain className="footer_containt">
              <FooterH5>Products</FooterH5>
              <FooterLink href="app.asteroneo.com" target="_blank">
                Exchange
              </FooterLink>
              <FooterLink href="#" target="_blank">
                Farming
              </FooterLink>
              <FooterLink href="#" target="_blank">
                Launchpad
              </FooterLink>
              <FooterLink href="https://neonauts.club" target="_blank">
                NFT
              </FooterLink>
            </ForMain>
          </MuiGridGridLg4>
          <MuiGridGridLg4 className="footer_containt_grid">
            <ForMain className="footer_containt">
              <FooterH5>Social</FooterH5>
              <div className="icon_all_sat">
                <FooterLink href="https://x.com/Asteroneo">
                  <img width={'140px'} src={darkMode ? TwiterDark : Twiter} alt="logo" />
                </FooterLink>
                <FooterLink href="https://t.me/asteroneo">
                  <img width={'140px'} src={darkMode ? TeligramDark : Teligram} alt="logo" />
                </FooterLink>
                <FooterLink href="https://medium.com/@asteroneo">
                  <img width={'140px'} src={darkMode ? MediumDark : Medium} alt="logo" />
                </FooterLink>
              </div>
              <div className="footer_link_text">
                <p>©2024. All Rights Reserved.</p>
              </div>
            </ForMain>
          </MuiGridGridLg4>
          {/* <MuiGridGridLg2>
                        <ForMain>
                            <FooterH5>Partnership</FooterH5>
                            <div className="partnerdiv">
                            <a href="https://www.geckoterminal.com/" target="_blank" rel="nofollow">
                            {darkMode ? 
                            <img src="./images/partner/geckoterminal_dark.png" width={"200px"} />
                            :
                            <img src="./images/partner/geckoterminal_light.png" width={"200px"} />
                            }
                            </a> 
                            </div>
                        </ForMain>
                    </MuiGridGridLg2> */}
        </MuiGridContainer>
      </AsFooter>
    </>
  );
}
