import React from 'react';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import Row from '../components/Row';
import { useTranslation } from 'react-i18next';
import { darken } from 'polished';
import SettingsTab from 'components/Settings';
import TimerButton from 'components/TimerButton';
import TokenBuilder from 'components/TokenBuilder';

export const BodyWrapper = styled.div`
  position: relative;
  max-width: 500px;
  width: 100%;
  padding: 0rem;
  border-radius: 1.6rem;
  box-shadow: rgba(0, 0, 0, 0.01) 0px 0px 1px, rgba(0, 0, 0, 0.04) 0px 4px 8px, rgba(0, 0, 0, 0.04) 0px 16px 24px,
    rgba(0, 0, 0, 0.01) 0px 24px 32px;
  background: #fff;

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    width: 100%;
  `}
`;
export const BodyWrapperInn = styled.div`
  position: relative;
  max-width: 680px;
  width: 100%;
  padding: 0.2rem;
  // border-radius: 1.6rem;
  // box-shadow: rgba(0, 0, 0, 0.01) 0px 0px 1px, rgba(0, 0, 0, 0.04) 0px 4px 8px, rgba(0, 0, 0, 0.04) 0px 16px 24px,
  //   rgba(0, 0, 0, 0.01) 0px 24px 32px;
  // background: ${({ theme }) => theme.bg1};
  // margin-bottom: -2px;
  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    width: 100%;
  `}
`;
const HeaderLinks = styled(Row)`
  width: auto;
  margin: 0 auto;
  padding: 0.3rem;
  justify-content: center;
`;
const activeClassName = 'ACTIVE';

const StyledALink = styled.a.attrs({
  activeClassName,
})`
  ${({ theme }) => theme.flexRowNoWrap}
  align-items: center;
  outline: none;
  cursor: pointer;
  min-width: 70px;
  text-decoration: none;
  color: ${({ theme }) => theme.text3};
  font-size: 18px;
  width: fit-content;
  padding: 0.8rem 0.6rem;
  font-weight: 700;
  transition: 0.3s;
  width: 33.33%;
  justify-content: center;
  &:not(:last-child) {
    margin-right: 0.16rem;
  }

  &.${activeClassName} {
    color: ${({ theme }) => theme.primary1};
  }

  :hover,
  :focus {
    color: ${({ theme }) => darken(0.1, theme.text1)};
  }
`;

const SoonLabel = styled.span`
  position: absolute;
  top: 0px;
  right: -5px;
  background-color: ${({ theme }) => theme.primary1};
  font-weight: 700;
  color: white;
  font-size: 10px;
  padding: 2px 4px;
  border-radius: 20px;
`;

const StyledALinkWrapper = styled.div`
  position: relative;
  display: inline-block;
`;

const StyledNavLink = styled(NavLink).attrs({
  activeClassName,
})`
  ${({ theme }) => theme.flexRowNoWrap}
  align-items: center;
  outline: none;
  cursor: pointer;
  min-width: 70px;
  text-decoration: none;
  color: ${({ theme }) => theme.text3};
  font-size: 18px;
  width: fit-content;
  padding: 0.8rem 1rem;
  font-weight: 700;
  transition: 0.3s;
  width: 33.33%;
  justify-content: center;
  &:not(:last-child) {
    margin-right: 0.16rem;
  }

  &.${activeClassName} {
    color: ${({ theme }) => theme.primary1};
  }

  :hover,
  :focus {
    color: ${({ theme }) => darken(0.1, theme.text1)};
  }
`;
export default function AppBody({ children }: { children: React.ReactNode }) {
  const { t } = useTranslation();
  return (
    <>
      <BodyWrapper className="swap_tabs_main">
        <HeaderLinks className="tabs_links_box">
          <div className="tabs_links">
            <StyledNavLink id={`swap-nav-link`} to={'/swap'} className="swap_links">
              {'Swap'}
            </StyledNavLink>
            <StyledNavLink
              id={`pool-nav-link`}
              to={'/pool'}
              isActive={(match, { pathname }) =>
                Boolean(match) ||
                pathname.startsWith('/add') ||
                pathname.startsWith('/remove') ||
                pathname.startsWith('/create') ||
                pathname.startsWith('/find')
              }
              className="swap_links"
            >
              {t('Liquidity')}
            </StyledNavLink>
            <StyledALinkWrapper>
            <StyledALink id={`swap-nav-link`} href={'#'} className="soon" target="_blank">
              {t('Farms')}
            </StyledALink>
            <SoonLabel>Soon</SoonLabel>
            </StyledALinkWrapper>
          </div>
          <div className="tabs_links">
            <TokenBuilder />
            <SettingsTab />
            <TimerButton />
          </div>
        </HeaderLinks>
        <BodyWrapperInn className="tokn_wdth_box">{children}</BodyWrapperInn>
      </BodyWrapper>
    </>
  );
}
