import React from 'react';
import styled from 'styled-components';
import { RowBetween } from '../Row';
import { TYPE } from '../../theme';

const StyledSwapHeader = styled.div`
  padding: 12px 1rem 0px 0rem;
  margin-bottom: 0.4rem;
  width: 100%;
  color: ${({ theme }) => theme.text2};
`;
const RoxBox = styled.div`
  // border-bottom: 2px solid #707070;
  padding-bottom: 15px;
`;

export default function SwapHeader() {
  return (
    <StyledSwapHeader>
      <RoxBox>
        <RowBetween>
          <div>
            {/* <TYPE.black fontWeight={700} fontSize={26}>Exchange</TYPE.black> */}
            <TYPE.black fontWeight={400} fontSize={14} marginTop={10} className="trading_text">
              The lowest trading fees on NEOX. <b>Only 0.17%</b>
            </TYPE.black>
          </div>
          {/* <RightPart>
            <Settings />
            <TimerButton />
          </RightPart> */}
        </RowBetween>
      </RoxBox>
    </StyledSwapHeader>
  );
}
